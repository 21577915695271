import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
  Layout,
  BlogHeader,
  LatestPosts,
  PopularPosts,
  Section,
  CategoryButtons,
  CategoryThreeCardCol,
  BlogFooterCta,
} from 'components';
import * as styles from './styles.module.scss';

const Blog = ({ location }) => {
  const data = useStaticQuery(blogLandingPageQuery);
  const {
    settings: { data: settingsData },
    latestPosts: { nodes: latestPostsNodes },
    popularPosts: { nodes: popularPostsNodes },
    categoryCarBuyingTips: { nodes: buyingTips },
    categoryCarFinanceTips: { nodes: financeTips },
    categoryCreditScoreTips: { nodes: creditTips },
  } = data;

  const {
    blog_category_buttons_title: buttonsTitle,
    blog_category_buttons_subtitle: buttonsSubtitle,
    blog_category_buttons: buttons,
    blog_title: blogTitle,
    blog_subtitle: blogSubtitle,
    blog_meta_description: metaDesc,
    blog_meta_title: metaTitle,
    blog_navigation_cta_text: navCtaText,
    blog_navigation_cta_link: navCtaLink,
  } = settingsData;

  const categoryButtons = {
    buttonsTitle,
    buttonsSubtitle,
    buttons,
  };

  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
  };

  const allCategories = [
    { title: 'Car Buying Tips', nodes: buyingTips },
    { title: 'Car Finance Tips', nodes: financeTips },
    { title: 'Credit Score Tips', nodes: creditTips },
  ];

  const navLink = { url: navCtaLink?.url, text: navCtaText?.text };

  return (
    <Layout className={styles.blogLanding} seo={seo} location={location} navLink={navLink}>
      <BlogHeader landingPage title={blogTitle} subtitle={blogSubtitle} />
      <Section>
        <div className={styles.postsContainer}>
          <LatestPosts latestPosts={latestPostsNodes} />
          <PopularPosts popularPosts={popularPostsNodes} />
        </div>
      </Section>
      <CategoryButtons data={categoryButtons} />
      {allCategories.map((category) => (
        <Fragment key={category?.title}>
          <CategoryThreeCardCol categoryPosts={category?.nodes} title={category?.title} />
        </Fragment>
      ))}
      <BlogFooterCta />
    </Layout>
  );
};
export default Blog;

export const blogLandingPageQuery = graphql`
  query BlogLandingPage {
    settings: prismicSettings {
      data {
        blog_category_buttons_title {
          html
          text
        }
        blog_category_buttons_subtitle {
          html
          text
        }
        blog_category_buttons {
          blog_category {
            uid
            document {
              ... on PrismicCategory {
                uid
                data {
                  category_name {
                    text
                  }
                }
              }
            }
          }
        }
        blog_meta_description {
          text
        }
        blog_meta_title {
          text
        }
        blog_title {
          html
          text
        }
        blog_subtitle {
          html
          text
        }
        blog_popular_posts_breakout_title {
          html
          text
        }
        blog_popular_posts_breakout_content {
          html
          text
        }
        blog_popular_posts_breakout_cta_text {
          text
        }
        blog_popular_posts_breakout_cta_link {
          url
        }
        blog_popular_posts_breakout_background_colour
        blog_navigation_cta_text {
          text
        }
        blog_navigation_cta_link {
          url
        }
      }
    }
    latestPosts: allPrismicBlogPost(sort: { fields: data___timestamp, order: DESC }, limit: 3) {
      nodes {
        uid
        data {
          title {
            text
            html
          }
          timestamp
          excerpt {
            html
            text
          }
          category {
            uid
            document {
              ... on PrismicCategory {
                uid
                data {
                  category_name {
                    text
                  }
                }
              }
            }
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.3333333333333335)
              }
            }
          }
        }
      }
    }
    popularPosts: allPrismicBlogPost(filter: { data: { is_popular: { eq: true } } }, limit: 3) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.3333333333333335)
              }
            }
          }
          category {
            uid
            document {
              ... on PrismicCategory {
                uid
                data {
                  category_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    categoryCarBuyingTips: allPrismicBlogPost(
      filter: { data: { category: { uid: { eq: "car-buying-tips" } } } }
      limit: 3
      sort: { fields: data___timestamp, order: DESC }
    ) {
      distinct(field: data___category___uid)
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.3333333333333335)
              }
            }
          }
          category {
            uid
            document {
              ... on PrismicCategory {
                uid
                data {
                  category_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    categoryCarFinanceTips: allPrismicBlogPost(
      filter: { data: { category: { uid: { eq: "car-finance-tips" } } } }
      limit: 3
      sort: { fields: data___timestamp, order: DESC }
    ) {
      distinct(field: data___category___uid)
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.3333333333333335)
              }
            }
          }
          category {
            uid
            document {
              ... on PrismicCategory {
                uid
                data {
                  category_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    categoryCreditScoreTips: allPrismicBlogPost(
      filter: { data: { category: { uid: { eq: "credit-score-tips" } } } }
      limit: 3
      sort: { fields: data___timestamp, order: DESC }
    ) {
      distinct(field: data___category___uid)
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.3333333333333335)
              }
            }
          }
          category {
            uid
            document {
              ... on PrismicCategory {
                uid
                data {
                  category_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
